<template>

    <PageWithButtonBelow :title="t('settings')">
        <template v-slot:above-title>
            <TinyNavigation :prev="{name: 'profile'}" />
        </template>

        <template v-slot:default>
            <div class="pt-10">
                <p class="text-base font-semibold text-gray-200 pb-2">{{ t('app') }}</p>

                <router-link :to="{name: 'profile.edit'}" class="flex w-full group rounded bg-gray-900 py-5 px-8 hover:bg-gray-800 hover:text-white  transition duration-200">
                    <span class="flex-1 text-gray-200 group-hover hover:text-white">
                        {{ t('edit_profile') }}
                    </span>
                    <div class="flex-initial text-scorelitorange">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </div>
                </router-link>

                <router-link :to="{name: 'profile.edit.password'}" class="flex w-full group rounded bg-gray-900 py-5 px-8 hover:bg-gray-800 hover:text-white  transition duration-200 mt-2">
                    <span class="flex-1 text-gray-200 group-hover hover:text-white">
                        {{ t('edit_password') }}
                    </span>
                    <div class="flex-initial text-scorelitorange">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </div>
                </router-link>
            </div>
            
            <!-- <div class="pt-10">
                <p class="text-sm text-gray-500 px-12 pb-2">Notificaties</p>

                <div @click="toggleReviewNotifications" class="flex w-full bg-gray-900 py-5 px-8 ">
                    <span class="flex-1 text-white">
                        {{ t('notifications_reviews') }}
                    </span>
                    <div class="flex-initial">
                        <span role="checkbox" tabindex="0" :aria-checked="notifications_reviews? 'true': 'false'" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
                            <span aria-hidden="true" :class="notifications_reviews ? 'translate-x-5' : 'translate-x-0'" class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                        </span>
                    </div>
                </div>

                <div @click="toggleNotifications" class="flex w-full bg-gray-900 py-5 px-8 mt-2">
                    <span class="flex-1 text-white">
                        {{ t('notifications_general') }}
                    </span>
                    <div class="flex-initial">
                        <span role="checkbox" tabindex="0" :aria-checked="notifications? 'true': 'false'" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline">
                            <span aria-hidden="true" :class="notifications ? 'translate-x-5' : 'translate-x-0'" class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"></span>
                        </span>
                    </div>
                </div>

            </div> -->
            <div class="pt-10">
                <p class="text-base font-semibold text-gray-200 pb-2">{{ t('legal') }}</p>

                <a href="https://www.scorelit.com/privacy-statement/" target="_blank" class="flex w-full rounded bg-gray-900 text-gray-200 py-5 px-8 hover:bg-gray-800 hover:text-white transition duration-200">
                    <span class="flex-1 text-white">
                        {{ t('privacy') }}
                    </span>
                    <div class="flex-initial text-scorelitorange">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </div>
                </a>

               <a href="https://www.scorelit.com/voorwaarden/" target="_blank" class="flex w-full rounded bg-gray-900 py-5 px-8 mt-2 text-gray-200 hover:bg-gray-800 hover:text-white transition duration-200">
                    <span class="flex-1 text-white">
                        {{ t('terms_of_service') }}
                    </span>
                    <div class="flex-initial text-scorelitorange">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </div>
                </a>
            </div>
        </template>

        <template v-slot:button>
            <span @click="logout" class="w-full rounded-md shadow-sm md:max-w-xl m-auto">
                <button type="button " class="button w-full">
                    {{ t('logout') }}
                </button>
            </span>
        </template>
    </PageWithButtonBelow>
</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import TinyNavigation from "@/components/TinyNavigation";
import { logout } from "@/helpers/api/AuthHelper";
import messages from "@/helpers/storage/MessageHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "Settings",
    setup() {
        return useI18n({
            viewPrefix: "Settings"
        });
    },
    components: { 
        TinyNavigation,
        PageWithButtonBelow
    },
    data(){
        return {
            notifications: false,
            notifications_reviews: false,
        }
    },
    methods:{
        toggleNotifications(){
            this.notifications = !this.notifications;
        },
        toggleReviewNotifications(){
            this.notifications_reviews = !this.notifications_reviews;
        },
        logout(){
            logout()
                .then(() => {
                    this.$router.push("/login");
                })
                .catch(err => {
                    messages.error(this.t('messages.logout_unsuccessful', {error: err.message}));
                    console.error('Logout unsuccessful: ', err.message)
                });
        },
    },
}
</script>